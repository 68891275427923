p {
    font-size: 1.1rem;
    line-height: 1.8em;
}

h2 {
    font-size: 4.2rem;
    font-weight: lighter;
    margin-bottom: 1rem;
}
