.PPF {
    text-align: center;
    margin-left: 10rem;
    margin-right: 10rem;
}

.outer {
    display: flex;
    flex-direction: row;
    align-items:normal;
}

.column {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
}


.Item {
    text-align: center;
    max-width: 100%;
    padding: 20px;
    max-height: 100%;
}


