.vids {
    display: flex;
    flex-direction: row;
    text-align: center;
    gap: 15px;
}

.item {
    flex-basis: 33.3%;
}
