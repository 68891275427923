.vids {
  display: flex;
  flex-direction: column;
  /* The flex-basis CSS property sets the initial main size of a flex item. */
  flex-basis: 48%;
  margin-bottom: 3rem;
}

 .layout {
  justify-content: space-between;
}

.layout {
  display: flex;
  flex-direction: row;
  /* The flex-wrap CSS property sets whether flex items are forced onto one line or can wrap onto multiple lines. */
  flex-wrap: wrap;
}


