.header {
    position: relative;
    z-index: 2;
    width: 100%;
    box-sizing: border-box;
    padding-top: 1.6vw;
    padding-bottom: 1.6vw;
    padding-left: 3vw;
    padding-right: 3vw;
}




