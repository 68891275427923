.Pho {
    text-align: center;
}

.phoRow {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: row;
}

.phoItem {
    max-width: 40%;
    padding: 20px;
    text-align: center;

}

.phoLoose {
    text-align: center;
    max-width: 50%;
}