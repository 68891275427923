.links {
    text-decoration: underline;
    color: hsl(222, 74%, 51%);
}

h1 {
    font-size: 2.8rem;
    font-weight: 400;
    letter-spacing: normal;
    font-style: italic;
}

h3 {
    text-align: center;
    font-size: 2.8rem;
    font-weight: 200;
}

.art {
    margin-bottom: 8rem;
    margin-right: 23vw;
}

.desc {
    font-size: 1rem;
    font-style: italic;
}