@font-face {
  font-family: "Merriweather";
  src: url(/src/Components/Assets/Font/Merriweather-Regular.ttf);
}

.body {
  display: flex;
  flex-direction: row;
  flex-basis: 50%;
  width: 100%;
  gap: 100px;
}

.dbBtn {
  color: white;
  background-color: black;
  font-family: Merriweather;
  font-weight: 500;
  font-size: 1.1rem;
  padding: 1em 2.5em;
}

.img {
  width: 70%;
}

img {
  max-width: 1000px;
}

h4 {
  font-size: 1.6rem;
}

.body p {
  font-size: 1.1rem;
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) {
  img {
    max-width: 650px;
  }

  h4 {
    font-size: 1.3rem;
  }

  .dbBtn {
    font-size: 0.9rem;
  }

  .body p {
    font-size: 0.9rem;
  }
}
