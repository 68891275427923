.content {
    display: flex;
    flex-direction: row;
    text-align: center;
    gap:15px;
}

.item {
    flex-basis: 33.3%;
}

.hiring {
    text-align: center;
}

.img1{
    width: 100%;
}
